import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem } from "@mui/material";
import * as React from "react";
import Table from "react-bootstrap/Table";

export default function GridTable({
  headers,
  data,
  iteratorKeys,
  page,
  handleUserDeactive,
  handleDelete,
  handleEdit,
  setRow,
  row,
  action = true,
  anchorEl,
  setAnchorEl,
  isLoading,
}) {
  const open = Boolean(anchorEl);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setRow(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="table-wrapper">
      <Table striped className="table">
        <thead>
          <tr className="tr">
            {headers.map((item) => (
              <th className="p-4 text-white">{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isLoading ? (
            data?.length > 0 ? (
              data.map((item, i) => (
                <tr
                  key={i}
                  className={data.length - 1 === i ? "tr_remove_border" : "tr"}
                >
                  {!!iteratorKeys?.length &&
                    iteratorKeys.map((key, index) => (
                      <td className="p-4 td" key={index}>
                        {typeof key === "function" ? key(item) : item[key]}
                      </td>
                    ))}

                  {action && (
                    <td className="p-3 td cursor-pointer">
                      <MoreHorizIcon
                        onClick={(event) => handleClick(event, item)}
                      />

                      {page === "users" && (
                        <MenuPop
                          open={open}
                          row={row}
                          handleClose={handleClose}
                          anchorEl={anchorEl}
                          handleUserDelete={handleDelete}
                          handleUserDeactive={handleUserDeactive}
                        />
                      )}

                      {page === "transactions" && (
                        <MenuTransaction
                          open={open}
                          handleClose={handleClose}
                          anchorEl={anchorEl}
                          handleDelete={handleDelete}
                          handleEdit={handleEdit}
                        />
                      )}

                      {page === "EnergyRates" && (
                        <MenuEnergyRates
                          open={open}
                          handleClose={handleClose}
                          handleDelete={handleDelete}
                          handleEdit={handleEdit}
                          anchorEl={anchorEl}
                        />
                      )}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length} className="text-center">
                  No Data Found
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={headers.length} className="text-center">
                Loading...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

const MenuPop = ({
  open,
  handleClose,
  handleUserDeactive,
  handleUserDelete,
  anchorEl,
  row,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiMenu-paper": {
          backgroundColor: "#1A232D",
          color: "white",
          borderRadius: 2,
        },
        boxShadow: 0,
      }}
      elevation={1}
    >
      <MenuItem onClick={handleUserDeactive}>
        {!row?.is_blocked ? "Block" : "Unblock"}
      </MenuItem>
      <MenuItem onClick={handleUserDelete} className="text-danger">
        Delete
      </MenuItem>
    </Menu>
  );
};

const MenuTransaction = ({ open, handleClose, handleDelete, handleEdit, anchorEl }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiMenu-paper": {
          backgroundColor: "#1A232D",
          color: "white",
          borderRadius: 2,
        },
        boxShadow: 0,
      }}
      elevation={1}
    >
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={handleDelete} className="text-danger">
        Delete
      </MenuItem>
    </Menu>
  );
};

const MenuEnergyRates = ({
  open,
  handleClose,
  handleDelete,
  handleEdit,
  anchorEl,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiMenu-paper": {
          backgroundColor: "#1A232D",
          color: "white",
          borderRadius: 2,
        },
        boxShadow: 0,
      }}
      elevation={1}
    >
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={handleDelete} className="text-danger">
        Delete
      </MenuItem>
    </Menu>
  );
};
