import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import GridTable from "../../Components/DataGrid/DataGrid";
import SearchInput from "../../Components/Search/Search";
import { useState } from "react";
import { useEffect } from "react";
import { getChargingService } from "../../services/charging.service";
import {
  addEnergy,
  deleteUser,
  getEnergyService,
  updateEnergy,
} from "../../services/energyRates.service";
import ThemeModal from "../../Components/modals/ThemeModal";
import { error, success } from "../../Hooks/useToast";
import AddEnergyRates from "../../Components/modals/AddEnergy";
import { MdArrowForwardIos } from "react-icons/md";
import { GrPrevious } from "react-icons/gr";

const tableHeaders = ["Utility Company", "Location", "Peak Hours", "Action"];

// const data = [
//   {
//     UtilityCompany: "Electrify America",
//     Location: "Coppell, Virginia",
//     PeakHours: "12:00pm - 12:30pm",
//   },
//   {
//     UtilityCompany: "Electrify America",
//     Location: "Coppell, Virginia",
//     PeakHours: "12:00pm - 12:30pm",
//   },
//   {
//     UtilityCompany: "Electrify America",
//     Location: "Coppell, Virginia",
//     PeakHours: "12:00pm - 12:30pm",
//   },
//   {
//     UtilityCompany: "Electrify America",
//     Location: "Coppell, Virginia",
//     PeakHours: "12:00pm - 12:30pm",
//   },
//   {
//     UtilityCompany: "Electrify America",
//     Location: "Coppell, Virginia",
//     PeakHours: "12:00pm - 12:30pm",
//   },
//   {
//     UtilityCompany: "Electrify America",
//     Location: "Coppell, Virginia",
//     PeakHours: "12:00pm - 12:30pm",
//   },
//   {
//     UtilityCompany: "Electrify America",
//     Location: "Coppell, Virginia",
//     PeakHours: "12:00pm - 12:30pm",
//   },
//   {
//     UtilityCompany: "Electrify America",
//     Location: "Coppell, Virginia",
//     PeakHours: "12:00pm - 12:30pm",
//   },
//   {
//     UtilityCompany: "Electrify America",
//     Location: "Coppell, Virginia",
//     PeakHours: "12:00pm - 12:30pm",
//   },
// ];

const iteratorKeys = [
  (row) => {
    return row?.name || "-";
  },
  (row) => {
    return row?.location || "-";
  },
  (row) => {
    return `${row?.peak_hour_start_time} - ${row?.peak_hour_end_time}` || "-";
  },
];
// moment(timeString).format("HH:mm:ss");
const EnergyRates = ({ setPage }) => {
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [row, setRow] = useState({});
  const [statusModal, setStatusModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false)
  const [editLoading, setEditLoading] = useState(false)

  useEffect(() => {
    GetEnergyRates();
  }, [pageNumber, searchText]);

  const GetEnergyRates = async () => {
    setIsLoading(true);
    const params = {
      page: pageNumber,
      search: searchText,
    };
    let res = await getEnergyService(params);
    setData(res?.data?.data);
    setPagination(res?.data?.links);
    setIsLoading(false);
  };
  const onSubmitDeleteModal = async () => {
    if (!row || !row.slug) {
      error("Row data is missing or slug is undefined");
      return;
    }

    let slug = row.slug;
    try {
      await deleteUser(slug);
    } catch (e) {
      error(e?.response?.data?.message || "An error occurred");
    } finally {
      setAnchorEl(null);
      setDeleteModal(false);
      GetEnergyRates();
    }
  };
  const onSubmitAddModal = async (data) => {
    setAddLoading(true)
    data["peak_hour_start_time"] = startTime.format("HH:mm:ss");
    data["peak_hour_end_time"] = endTime.format("HH:mm:ss");
    data["region_slug"] = data?.region?.value;
    try {
      const res = await addEnergy(data);
      success(res?.data?.message);
      setAddModal(false);
      setAddLoading(false);
      GetEnergyRates();
    } catch (e) {
      error(e.response.data.message);
      setAddLoading(false);
    }
  };

  const onSubmitEditModal = async (data) => {
    setEditLoading(true)
    data["peak_hour_start_time"] = startTime.format("HH:mm:ss");;
    data["peak_hour_end_time"] = endTime.format("HH:mm:ss");;
    data["region_slug"] = data?.region?.value;
    if (!row || !row.slug) {
      error("Row data is missing or slug is undefined");
      return;
    }

    let slug = row.slug;
    try {
      await updateEnergy(slug, data);
      setEditModal(false);
      setEditLoading(false)
      GetEnergyRates();
    } catch (e) {
      error(e?.response?.data?.message || "An error occurred");
    } finally {
      setAnchorEl(null);
      setEditLoading(false)
    }
  };
  const handleDelete = async () => {
    setAnchorEl(null);
    setDeleteModal(true);
  };
  const handleEdit = async () => {
    setAnchorEl(null);
    setEditModal(true);
  };
  return (
    <div className={`users focus-in-expand-slow `}>
      <Row className="mb-3">
        <Col>
          <h2 className="text-white">Rates & Peak Hours Management</h2>
        </Col>
        <Col>
          <div className="d-flex align-items-center justify-content-end gap-3">
            <div className="plus_btn" onClick={() => setAddModal(true)}>
              <PlusSign /> <div>Add</div>
            </div>
            <SearchInput setSearchText={setSearchText} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <GridTable
            headers={tableHeaders}
            data={data}
            iteratorKeys={iteratorKeys}
            page={"EnergyRates"}
            setRow={setRow}
            row={row}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            isLoading={isLoading}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
          <div className="page-table-pagination d-flex ms-auto justify-content-end">
            <Button
              disabled={pagination?.prev === 0 ? true : false}
              onClick={() => setPageNumber(pageNumber - 1)}
              className="paginated-prev"
            >
              <GrPrevious color="black" />
            </Button>
            <span className="text">
              {pagination?.current}/ {pagination?.total}
            </span>
            <Button
              disabled={
                pagination?.current === pagination?.total ? true : false
              }
              onClick={() => setPageNumber(pageNumber + 1)}
              className="paginated-forward"
            >
              <MdArrowForwardIos color="black" />
            </Button>
          </div>

        </Col>
      </Row>
      <ThemeModal
        setState={setDeleteModal}
        initialState={deleteModal}
        onSubmitModal={onSubmitDeleteModal}
        cancelText={"Cancel"}
        deletetext={"Confirm"}
        isRed
        heading={"Are you sure you want to Delete?"}
        Text={"Click Confirm to Delete"}
      />
      <ThemeModal
        setState={setAddModal}
        initialState={addModal}
        children={
          <AddEnergyRates
            onSubmit={onSubmitAddModal}
            btntext={"Save"}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            isLoading={addLoading}
          />
        }
        // cancelText={"Cancel"}
        heading={"Add Details"}
      // Text={"Click Confirm to log out"}
      />
      <ThemeModal
        setState={setEditModal}
        initialState={editModal}
        children={
          <AddEnergyRates
            onSubmit={onSubmitEditModal}
            btntext={"Save"}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            startTime={startTime}
            endTime={endTime}
            row={row}
            isLoading={editLoading}
          />
        }
        // cancelText={"Cancel"}
        heading={"Edit Details"}
      // Text={"Click Confirm to log out"}
      />
    </div>
  );
};

export default EnergyRates;

const PlusSign = () => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.04 4.214V5.686H5.946V9.78H4.474V5.686H0.38V4.214H4.474V0.12H5.946V4.214H10.04Z"
        fill="#BFBFBF"
      />
    </svg>
  );
};
