import React from "react";

import styles from "./styles/AccountLayout.module.scss";

import { IoIosArrowBack } from "react-icons/io";

import { ShadowContainer } from "../CustomComponents";

export default function AccountLayout({
  hasBackButton = false,
  handleBackButton = () => {},
  children,
  leftSideImage,
}) {
  return (
    <div
      className={styles.AccountLayout}
      style={{
        backgroundImage: "url(/images/public_background_flux_app.jpeg)",
      }}
    >
      <ShadowContainer />
      <div className={styles.sectionContainer}>
        <di className={styles.row}>
          <div className={`${styles.formContainer} p-0`}>
            <div className={styles.form} style={{ maxWidth: "420px" }}>
              {hasBackButton ? (
                <div
                  className={styles.backButtonContainer}
                  onClick={handleBackButton}
                >
                  <IoIosArrowBack />
                </div>
              ) : (
                ""
              )}
              {children}
            </div>
          </div>
        </di>
      </div>
    </div>
  );
}
