import React from "react";

const TransactionSvgIcon = ({ color = "white" }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9637 9.48187C18.9637 14.7103 14.7103 18.9637 9.48187 18.9637C4.25341 18.9637 0 14.7103 0 9.48187C0 4.25341 4.25341 0 9.48187 0C9.91882 0 10.272 0.3532 10.272 0.790156C10.272 1.22711 9.91882 1.58031 9.48187 1.58031C5.12495 1.58031 1.58031 5.12495 1.58031 9.48187C1.58031 13.8388 5.12495 17.3834 9.48187 17.3834C13.8388 17.3834 17.3834 13.8388 17.3834 9.48187C17.3834 9.04491 17.7366 8.69171 18.1736 8.69171C18.6105 8.69171 18.9637 9.04491 18.9637 9.48187ZM17.3834 0H14.2228C13.7858 0 13.4326 0.3532 13.4326 0.790156C13.4326 1.22711 13.7858 1.58031 14.2228 1.58031H16.2661L13.6642 4.18229C13.3552 4.49125 13.3552 4.99062 13.6642 5.29957C13.8182 5.45365 14.0205 5.53109 14.2228 5.53109C14.4251 5.53109 14.6274 5.45365 14.7814 5.29957L17.3834 2.69759V4.74093C17.3834 5.17789 17.7366 5.53109 18.1736 5.53109C18.6105 5.53109 18.9637 5.17789 18.9637 4.74093V1.58031C18.9637 0.70877 18.255 0 17.3834 0ZM10.272 4.74093C10.272 4.30398 9.91882 3.95078 9.48187 3.95078C9.04491 3.95078 8.69171 4.30398 8.69171 4.74093V5.53109C7.3848 5.53109 6.32125 6.59464 6.32125 7.90156C6.32125 8.97538 7.09086 9.88485 8.15046 10.0611L10.5525 10.4609C10.848 10.5099 11.0622 10.7627 11.0622 11.0622C11.0622 11.4983 10.7082 11.8523 10.272 11.8523H8.47995C8.19866 11.8523 7.93632 11.7014 7.79489 11.4565C7.5768 11.078 7.09402 10.9484 6.71553 11.1689C6.33784 11.3869 6.20825 11.8705 6.42792 12.2482C6.84986 12.9791 7.63685 13.4326 8.48074 13.4326H8.6925V14.2228C8.6925 14.6598 9.0457 15.013 9.48266 15.013C9.91961 15.013 10.2728 14.6598 10.2728 14.2228V13.4326C11.5797 13.4326 12.6433 12.3691 12.6433 11.0622C12.6433 9.98836 11.8737 9.07889 10.8141 8.90268L8.412 8.50287C8.11648 8.45388 7.90235 8.20103 7.90235 7.90156C7.90235 7.46539 8.25634 7.1114 8.6925 7.1114H10.4846C10.7659 7.1114 11.0282 7.26232 11.1696 7.50727C11.3869 7.88496 11.8697 8.01455 12.249 7.79489C12.6267 7.5768 12.7563 7.09323 12.5366 6.71553C12.1147 5.98464 11.3277 5.53109 10.4838 5.53109H10.272V4.74093Z"
        fill={color}
      />
    </svg>
  );
};

export default TransactionSvgIcon;
