import { TimePicker } from "@mui/x-date-pickers";
import React from "react";
import "./timeFieldComponent.css";

const TimeFieldComponent = ({ placeholder, onChange, value }) => {
  // const [value, setValue] = React.useState(dayjs('2022-04-17'));

  return (
    <div
      style={{
        width: "100%",
        // display: 'flex',
        // justifyContent: 'flex-start',
        // position: 'relative',
      }}
      onClick={() => {}}
    >
      <TimePicker
        className={"timePicker mb-1 mt-1 bg-darkJungleGreen"}
        label={placeholder}
        value={value}
        sx={{
          width: "100%",
          // height: '47px',
          margin: "0px 5px 0px 5px",
          color: "#FFF",
          borderRadius: 6,
          borderWidth: 1,
          border: "1px solid #48556F",
          backgroundColor: "#222232",
        }}
        // value={value}
        // onChange={
        //     (newValue) => {
        //         setValue(newValue)
        //     }}
        onChange={onChange}
      />
    </div>
  );
};

export default TimeFieldComponent;
