import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../Components/CommonComponents/Logo";
import { BackButton, Button, Input } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { IMAGES } from "../../Constant/ImageConstant";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { LoginPayload } from "../../Constant/PayloadConstant/LoginPayloadConstant";
import { STRING } from "../../Constant/StringConstant";
import Toast, { error, success } from "../../Hooks/useToast";
import { ACCOUNT_ENUM } from "./Route";
import ForgotPasswordInstructions from "./Components/ForgotPasswordInstructions";
import { setForgotEmail } from "../../services/Redux/Slices/AuthSlice";
import { ForgotService } from "../../services/auth.service";

export default function ForgotPassword({ setPage, setEmail }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // defaultValues:{
    //     email: '',
    //     password : '',
    // }
  });

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const handleSignUpClick = () => {
    setPage(ACCOUNT_ENUM.REGISTER);
  };

  // const handleOtpPage = () => {
  //     setPage(ACCOUNT_ENUM.OTP)
  // }

  const handleForgotEmail = async (data) => {
    console.log(data);

    dispatch(setForgotEmail(data));
    try {
      const res = await ForgotService(data);
      success(res?.data?.message);
      handleGotoSetPassword();
    } catch (e) {
      error(e?.response?.data?.message);
    }
  };

  //todo : for alpha
  const handleGotoSetPassword = async () => {
    setPage(ACCOUNT_ENUM.SET_PASSWORD);
  };

  return (
    <AccountLayout
      leftSideImage={IMAGES.PASSWORD}
      // hasBackButton={true}
      handleBackButton={handleBackButtonClick}
    >
      {errors
        ? errors?.email?.message
          ? Toast(errors?.email?.message, "error")
          : null
        : ""}

      <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />

      <Logo />

      <ForgotPasswordInstructions />

      <Form onSubmit={handleSubmit(handleForgotEmail)} className="w-100">
        <Controller
          control={control}
          name={LoginPayload.Email}
          // rules={{
          //     required: STRING.EmailRequired,
          //     pattern: {
          //         value: INPUT_PATTERNS.EMAIL_PATTERNS,
          //         message: STRING.ValidEmailRequired,
          //     },
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder={STRING.EmailAddress}
                type={INPUT_TYPES.EMAIL}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {
                // <div className="input-before-icon" >
                //     <CiUser />
                // </div>
              }
            </InputGroup>
          )}
        />

        <Button
          classes="mt-3 mb-2 scale-in-center-slow"
          text={STRING.Continue}
          type={INPUT_TYPES.SUBMIT}
          // onClick={handleSubmit}
        />
      </Form>
    </AccountLayout>
  );
}
