import React from 'react'
import { IMAGES } from '../../Constant/ImageConstant'

const Logo = () => {
    return (
        <div className='d-flex justify-content-center mb-4 bounce-in-right'>
            <img src={IMAGES.LOGO} alt='Flux App Logo ' style={{ width: '100%' }} />
        </div>
    )
}

export default Logo
