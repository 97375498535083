import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import GridTable from "../../Components/DataGrid/DataGrid";
import SearchInput from "../../Components/Search/Search";
import { useState } from "react";
import {
  blockUnblockUser,
  deleteUser,
  getUserService,
} from "../../services/user.service";
import { useEffect } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { GrPrevious } from "react-icons/gr";
import Toast, { error, success, SuccessToast } from "../../Hooks/useToast";
import ThemeModal from "../../Components/modals/ThemeModal";

const tableHeaders = [
  "User Name",
  "Email",
  "Address",
  "Utility Company",
  "Action",
];

// const data = [
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },

//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
//   {
//     UserName: "Bessie",
//     Email: "trungkienspktnd@gamail.com",
//     Address: "Oregon",
//     CarType: "f 150 Lightning 2022",
//     UtilityCompany: "Idaho Power Company",
//   },
// ];

const iteratorKeys = [
  (row) => {
    return `${row?.first_name || "-"} ${row?.last_name}`;
  },
  "email",
  (row) => {
    return `${row?.user_company?.region_company?.name || "-"}`;
  },
  (row) => {
    return `${row?.user_company?.name || "-"}`;
  },
];

const User = ({ setPage }) => {
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [row, setRow] = useState({});
  const [statusModal, setStatusModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    GetUserData();
  }, [pageNumber, searchText]);

  const GetUserData = async () => {
    setIsLoading(true);
    const params = {
      page: pageNumber,
      search: searchText,
    };
    let res = await getUserService(params);
    setData(res?.data?.data);
    setPagination(res?.data?.links);
    setIsLoading(false);
  };

  const handleUserDeactive = async () => {
    setAnchorEl(null);
    setStatusModal(true);
  };

  const handleUserDelete = async () => {
    setAnchorEl(null);
    setDeleteModal(true);
  };

  const onSubmitStatusModal = async () => {
    if (!row || !row.slug) {
      error("Row data is missing or slug is undefined");
      return;
    }

    let checkStatus = row.is_blocked;
    let payload = { is_blocked: !checkStatus };
    let slug = row.slug;
    try {
      await blockUnblockUser(slug, payload);
    } catch (e) {
      error(e?.response?.data?.message || "An error occurred");
    } finally {
      setAnchorEl(null);
      setStatusModal(false);
      GetUserData();
    }
  };
  const onSubmitDeleteModal = async () => {
    if (!row || !row.slug) {
      error("Row data is missing or slug is undefined");
      return;
    }

    let slug = row.slug;
    try {
      await deleteUser(slug);
    } catch (e) {
      error(e?.response?.data?.message || "An error occurred");
    } finally {
      setAnchorEl(null);
      setDeleteModal(false);
      GetUserData();
    }
  };

  return (
    <div className={`users focus-in-expand-slow `}>
      <Row className="mb-3">
        <Col>
          <h2 className="text-white">Users</h2>
        </Col>
        <Col>
          <SearchInput setSearchText={setSearchText} />
        </Col>
      </Row>
      <Row>
        <Col>
          <GridTable
            headers={tableHeaders}
            data={data}
            iteratorKeys={iteratorKeys}
            page={"users"}
            handleDelete={handleUserDelete}
            handleUserDeactive={handleUserDeactive}
            setRow={setRow}
            row={row}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            isLoading={isLoading}
          />
          <div className="page-table-pagination d-flex ms-auto justify-content-end">
            <Button
              disabled={pagination?.prev === 0 ? true : false}
              onClick={() => setPageNumber(pageNumber - 1)}
              className="paginated-prev"
            >
              <GrPrevious color="black" />
            </Button>
            <span className="text">
              {pagination?.current}/ {pagination?.total}
            </span>
            <Button
              disabled={
                pagination?.current === pagination?.total ? true : false
              }
              onClick={() => setPageNumber(pageNumber + 1)}
              className="paginated-forward"
            >
              <MdArrowForwardIos color="black" />
            </Button>
          </div>
          {/* {data?.length === 0 && <p>No users found</p>} */}
        </Col>
      </Row>
      <ThemeModal
        setState={setStatusModal}
        initialState={statusModal}
        onSubmitModal={onSubmitStatusModal}
        cancelText={"Cancel"}
        deletetext={row.is_blocked ? "UnBlock" : "Block"}
        isRed={row.is_blocked ? false : true}
        isgreen={row.is_blocked ? true : false}
        heading={`Are you sure you want to ${row.is_blocked ? "UnBlock" : "Block"
          }?`}
      // Text={"Click Confirm to log out"}
      />
      <ThemeModal
        setState={setDeleteModal}
        initialState={deleteModal}
        onSubmitModal={onSubmitDeleteModal}
        cancelText={"Cancel"}
        deletetext={"Confirm"}
        isRed
        heading={"Are you sure you want to Delete?"}
        Text={"Click Confirm to Delete"}
      />
    </div>
  );
};

export default User;
