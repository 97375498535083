import React from "react";

const FilterIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.09976 3.16677H2.92372C3.06681 3.69325 3.37916 4.15801 3.81258 4.48936C4.24601 4.82071 4.77643 5.00024 5.322 5.00024C5.86757 5.00024 6.39799 4.82071 6.83142 4.48936C7.26484 4.15801 7.57719 3.69325 7.72028 3.16677H15.7661C15.9429 3.16677 16.1125 3.09653 16.2375 2.97151C16.3625 2.84649 16.4328 2.67693 16.4328 2.50012C16.4328 2.32331 16.3625 2.15375 16.2375 2.02872C16.1125 1.9037 15.9429 1.83347 15.7661 1.83347H7.72028C7.57719 1.30699 7.26484 0.842225 6.83142 0.510874C6.39799 0.179524 5.86757 0 5.322 0C4.77643 0 4.24601 0.179524 3.81258 0.510874C3.37916 0.842225 3.06681 1.30699 2.92372 1.83347H1.09976C0.922951 1.83347 0.753385 1.9037 0.628363 2.02872C0.503342 2.15375 0.433105 2.32331 0.433105 2.50012C0.433105 2.67693 0.503342 2.84649 0.628363 2.97151C0.753385 3.09653 0.922951 3.16677 1.09976 3.16677ZM5.32167 1.33348C5.55241 1.33348 5.77796 1.4019 5.96982 1.53009C6.16167 1.65828 6.3112 1.84049 6.3995 2.05366C6.4878 2.26684 6.51091 2.50141 6.46589 2.72772C6.42088 2.95403 6.30976 3.1619 6.14661 3.32506C5.98345 3.48822 5.77557 3.59933 5.54927 3.64434C5.32296 3.68936 5.08839 3.66626 4.87521 3.57796C4.66204 3.48965 4.47983 3.34012 4.35164 3.14827C4.22345 2.95642 4.15503 2.73086 4.15503 2.50012C4.15538 2.19081 4.27841 1.89428 4.49712 1.67557C4.71583 1.45686 5.01236 1.33383 5.32167 1.33348Z"
      fill="white"
    />
    <path
      d="M15.7661 7.33338H13.9421C13.7993 6.80679 13.4871 6.34185 13.0537 6.01036C12.6203 5.67888 12.0898 5.49927 11.5442 5.49927C10.9986 5.49927 10.4681 5.67888 10.0347 6.01036C9.60133 6.34185 9.2891 6.80679 9.14625 7.33338H1.09976C0.922951 7.33338 0.753385 7.40362 0.628363 7.52864C0.503342 7.65366 0.433105 7.82323 0.433105 8.00004C0.433105 8.17684 0.503342 8.34641 0.628363 8.47143C0.753385 8.59645 0.922951 8.66669 1.09976 8.66669H9.14625C9.2891 9.19329 9.60133 9.65822 10.0347 9.98971C10.4681 10.3212 10.9986 10.5008 11.5442 10.5008C12.0898 10.5008 12.6203 10.3212 13.0537 9.98971C13.4871 9.65822 13.7993 9.19329 13.9421 8.66669H15.7661C15.9429 8.66669 16.1125 8.59645 16.2375 8.47143C16.3625 8.34641 16.4328 8.17684 16.4328 8.00004C16.4328 7.82323 16.3625 7.65366 16.2375 7.52864C16.1125 7.40362 15.9429 7.33338 15.7661 7.33338ZM11.5442 9.16668C11.3135 9.16668 11.0879 9.09826 10.896 8.97006C10.7042 8.84187 10.5547 8.65967 10.4664 8.44649C10.3781 8.23331 10.355 7.99874 10.4 7.77244C10.445 7.54613 10.5561 7.33825 10.7193 7.1751C10.8824 7.01194 11.0903 6.90083 11.3166 6.85581C11.5429 6.8108 11.7775 6.8339 11.9907 6.9222C12.2038 7.0105 12.386 7.16003 12.5142 7.35188C12.6424 7.54374 12.7108 7.7693 12.7108 8.00004C12.7105 8.30934 12.5875 8.60588 12.3687 8.82459C12.15 9.0433 11.8535 9.16632 11.5442 9.16668Z"
      fill="white"
    />
    <path
      d="M15.7661 12.8332H7.72028C7.57719 12.3067 7.26484 11.842 6.83142 11.5106C6.39799 11.1793 5.86757 10.9998 5.322 10.9998C4.77643 10.9998 4.24601 11.1793 3.81258 11.5106C3.37916 11.842 3.06681 12.3067 2.92372 12.8332H1.09976C0.922951 12.8332 0.753385 12.9035 0.628363 13.0285C0.503342 13.1535 0.433105 13.3231 0.433105 13.4999C0.433105 13.6767 0.503342 13.8462 0.628363 13.9713C0.753385 14.0963 0.922951 14.1665 1.09976 14.1665H2.92372C3.06681 14.693 3.37916 15.1578 3.81258 15.4891C4.24601 15.8205 4.77643 16 5.322 16C5.86757 16 6.39799 15.8205 6.83142 15.4891C7.26484 15.1578 7.57719 14.693 7.72028 14.1665H15.7661C15.9429 14.1665 16.1125 14.0963 16.2375 13.9713C16.3625 13.8462 16.4328 13.6767 16.4328 13.4999C16.4328 13.3231 16.3625 13.1535 16.2375 13.0285C16.1125 12.9035 15.9429 12.8332 15.7661 12.8332ZM5.32167 14.6665C5.09093 14.6665 4.86537 14.5981 4.67352 14.4699C4.48166 14.3417 4.33213 14.1595 4.24383 13.9463C4.15553 13.7332 4.13243 13.4986 4.17744 13.2723C4.22246 13.046 4.33357 12.8381 4.49673 12.6749C4.65988 12.5118 4.86776 12.4007 5.09407 12.3557C5.32037 12.3106 5.55494 12.3337 5.76812 12.422C5.9813 12.5103 6.1635 12.6599 6.29169 12.8517C6.41989 13.0436 6.48831 13.2691 6.48831 13.4999C6.48778 13.8091 6.3647 14.1056 6.14602 14.3242C5.92735 14.5429 5.63092 14.666 5.32167 14.6665Z"
      fill="white"
    />
  </svg>
);

const SearchIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8013 15.8006L12.5768 11.576C13.728 10.168 14.294 8.37136 14.1578 6.55772C14.0215 4.74409 13.1934 3.05221 11.8446 1.83204C10.4959 0.611865 8.72984 -0.0432431 6.91166 0.0022171C5.09348 0.0476773 3.36232 0.790227 2.07628 2.07628C0.790227 3.36232 0.0476773 5.09348 0.0022171 6.91166C-0.0432431 8.72984 0.611865 10.4959 1.83204 11.8446C3.05221 13.1934 4.74409 14.0215 6.55772 14.1578C8.37136 14.294 10.168 13.728 11.576 12.5768L15.8006 16.8013C15.934 16.9303 16.1128 17.0016 16.2984 17C16.484 16.9984 16.6615 16.9239 16.7927 16.7927C16.9239 16.6615 16.9984 16.484 17 16.2984C17.0016 16.1128 16.9303 15.934 16.8013 15.8006ZM7.10017 12.7622C5.98033 12.7622 4.88564 12.4301 3.95453 11.808C3.02341 11.1858 2.2977 10.3015 1.86915 9.26693C1.44061 8.23233 1.32848 7.09389 1.54695 5.99557C1.76542 4.89724 2.30468 3.88837 3.09652 3.09652C3.88837 2.30468 4.89724 1.76542 5.99557 1.54695C7.09389 1.32848 8.23233 1.44061 9.26693 1.86915C10.3015 2.2977 11.1858 3.02341 11.808 3.95453C12.4301 4.88564 12.7622 5.98033 12.7622 7.10017C12.7605 8.60131 12.1634 10.0405 11.102 11.102C10.0405 12.1634 8.60131 12.7605 7.10017 12.7622Z"
      fill="#BFBFBF"
    />
  </svg>
);

export { FilterIcon, SearchIcon };
