import * as React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { error, success } from "../../Hooks/useToast";
import { Controller, useForm } from "react-hook-form";
import ThemeSelect from "../../Components/ThemeSelect";
import { useState } from "react";
import { getEnergyService } from "../../services/energyRates.service";
import { useEffect } from "react";
import CustomInput from "../../Components/ThemeInput";
import { addCalculation } from "../../services/calculation.service";

const RateCalculations = ({ setPage }) => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    GetEnergyService();
  }, []);

  const GetEnergyService = async () => {
    let res = await getEnergyService();
    let option = res?.data?.data?.map((dt) => ({
      value: dt?.slug,
      label: dt?.name,
    }));
    setData(option);
  };
  const onSubmit = async (data) => {
    setIsloading(true);
    data["company_slug"] = data?.company_slug?.value;
    data["type"] = data?.type?.label;
    try {
      const res = await addCalculation(data);
      setCalculatedAmount(res?.data?.data?.total);
      success(res?.data?.message);
      setIsloading(false);
    } catch (e) {
      error(e.response.data.message);
    }
  };

  return (
    <div className={`users focus-in-expand-slow `}>
      <Row className="mb-3">
        <Col>
          <h2 className="text-white">Rate & Calculation</h2>
        </Col>
      </Row>
      <div className="add-modal">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <div className="mt-4">
                <ThemeSelect
                  name="company_slug"
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: "Required",
                    },
                  }}
                  label="Role"
                  placeholder="Select"
                  options={data}
                />
              </div>
              <div className="mt-4">
                <ThemeSelect
                  name="type"
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: "Required",
                    },
                  }}
                  label="Role"
                  placeholder="Select Type"
                  options={[
                    { value: 1, label: "charge" },
                    { value: 2, label: "discharge" },
                  ]}
                />
              </div>
              <div className="mt-3">
                <Controller
                  control={control}
                  name="total_normal_hours"
                  rules={{
                    required: {
                      value: true,
                    },
                  }}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Name"
                      placeholder="Total Normal Hours"
                      className="name"
                      type="number"
                      required={true}
                      maxLength={10}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                {errors.total_normal_hours && (
                  <small className="text-start d-block text-danger">
                    {errors.total_normal_hours.message}
                  </small>
                )}
              </div>
              <div className="mt-3">
                <Controller
                  control={control}
                  name="total_peak_hours"
                  rules={{
                    required: {
                      value: true,
                    },
                  }}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Name"
                      placeholder="Total Peak Hours"
                      className="name"
                      type="number"
                      required={true}
                      maxLength={10}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                {errors.total_peak_hours && (
                  <small className="text-start d-block text-danger">
                    {errors.total_peak_hours.message}
                  </small>
                )}
              </div>
              <div className="d-flex mt-3">
                <Button
                  style={{ backgroundColor: "grey", border: "none" }}
                  disabled={isloading}
                  type="submit"
                  className="btn-solid btn-okay ms-auto"
                >
                  Calculate
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col md={6}>
            <div className="calculated-amount">
              Total: <span>{isloading ? "----" : calculatedAmount}</span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RateCalculations;
