import React, { useState } from "react";

import OtpInput from "react-otp-input";

import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, InputOTP, SubTitle } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { ACCOUNT_ENUM } from "./Route";

export default function OTP({ setPage, email }) {
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log('OTP')
  // console.log(email)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // defaultValues:{
    //     email: '',
    //     password : '',
    // }
  });

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleSetPassword = () => {
    setPage(ACCOUNT_ENUM.SET_PASSWORD);
  };


  const handleVerifyOtp = async (data) => {
    data.email = await email;
    console.log(data);

    console.log(data);

    // // try {
    // //   const response = await verifyEmailOtp(data);

    // //   console.log(response);

    // //   if (response.error && response.error.status === 400) {
    // //     const message = await response.error.data.errors;
    // //     Toast(`${message}`, "error");
    // //     // console.log(message)
    // //   }

    // //   if (!response.error && response.data.statusCode === 200) {
    // //     const token = response.data.data.token[0].api_token;
    // //     const email = response.data.data.email;
    // //     const name = response.data.data.full_name;
    // //     dispatch(
    // //       setAuthInfo({
    // //         token: token,
    // //         email: email,
    // //         name: name,
    // //         isLoggedIn: true,
    // //         keepLoggedIn: "",
    // //       })
    // //     );
    // //     storeToken(token);
    // //     SuccessToast(response?.data?.message);
    // //     handleSetPassword();
    // //   }
    // } catch (error) {
    //   console.log(error);
    //   Toast("Something went wrong.", "error");
    // }
  };

  return (
    <AccountLayout
      hasBackButton={false}
      handleBackButton={handleBackButtonClick}
    >
      {errors
        ? errors?.otp?.message
          ? Toast(errors?.otp?.message, "error")
          : null
        : ""}

      <div className="d-flex justify-content-center">
        <img
          src="/images/logo.png"
          alt="Logo Assets"
          style={{ width: "30%" }}
        />
      </div>
      <br></br>

      <SubTitle text="Verify OTP" classes="mb-1 akiraFont" />

      <p
        style={{
          letterSpacing: "12px",
          paddingLeft: "13px",
          wordSpacing: "-10px",
        }}
      >
        OF YOUR <span style={{ color: "#D43737" }}>ACCOUNT</span>
      </p>

      <Form onSubmit={handleSubmit(handleVerifyOtp)}>
        <Controller
          control={control}
          name="otp"
          rules={{
            required: "OTP is required.",
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <OtpInput
              // value={otp}
              // onChange={setOtp}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              numInputs={6}
              renderSeparator={<span> </span>}
              renderInput={(props) => <InputOTP props={props} />}
            />
          )}
        />
        <Button
          text="Forgot Passoword"
          classes="mt-5 mb-2"
          // onClick={handleSubmit}
          type="submit"
        />
      </Form>
    </AccountLayout>
  );
}
