import api from ".";
import { apiUrl, constant } from "../Constant/constants";

export const getAllTransaction = (params) =>
  api.get(`${constant.VER}${constant.admin}${apiUrl.transaction}`, {
    params,
  });

export const deleteTransaction = (slug) => {
  const url = `${constant.VER}${constant.admin}${apiUrl.transaction}/${slug}`;
  return api.delete(url);
};



export const addTransaction = (data) =>
  api.post(`${constant.VER}${constant.admin}${apiUrl.transaction}`, data);

// export const updateTransaction = (slug, data) =>
//   api.patch(`${constant.VER}${constant.admin}${apiUrl.company}${slug}`, data);

export const updateTransaction = (slug, data) => {
  const url = `${constant.VER}${constant.admin}${apiUrl.transaction}/${slug}`;
  return api.patch(url, data);
};
