import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import GridTable from "../../Components/DataGrid/DataGrid";
import SearchInput from "../../Components/Search/Search";
import { useState } from "react";
import { useEffect } from "react";
import { getChargingService } from "../../services/charging.service";
import { MdArrowForwardIos } from "react-icons/md";
import { GrPrevious } from "react-icons/gr";

const tableHeaders = ["User Name", "Vehicle", "Battery %", "Status"];

const iteratorKeys = [
  (row) => {
    return row?.user_vehicle?.first_name + " " + row?.user_vehicle?.last_name;
  },
  "make",
  (row) => {
    return BatteryIcon(row?.battery);
  },
  "current_state",
];

const ChargeTag = {
  1: <div className="charging">Charging</div>,
  2: <div className="discharging">Discharging</div>,
  3: <div className="idle">Idle</div>,
};

const AmountComp = (amount) => {
  if (amount.includes("+")) {
    return <div className="charging">{amount}</div>;
  } else if (amount.includes("-")) {
    return <div className="idle">{amount}</div>;
  }
};

const BatteryIcon = (battery) => {
  if (battery >= "85") {
    return (
      <div className="battery_container">
        <img src="/images/green_battery.png" alt="" />
        <div className="charging">{battery}%</div>
      </div>
    );
  } else if (battery >= "55" || battery >= "16") {
    return (
      <div className="battery_container">
        <img src="/images/orange_battery.png" alt="" />
        <div className="discharging">{battery}%</div>
      </div>
    );
  } else if (battery <= "15") {
    return (
      <div className="battery_container">
        <img src="/images/red_battery.png" alt="" />
        <div className="idle">{battery}%</div>
      </div>
    );
  }
};

const ChargingControl = ({ setPage }) => {
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [row, setRow] = useState({});
  const [statusModal, setStatusModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    GetUserData();
  }, [pageNumber, searchText]);

  const GetUserData = async () => {
    setIsLoading(true);
    const params = {
      page: pageNumber,
      search: searchText,
    };
    let res = await getChargingService(params);
    setData(res?.data?.data);
    setPagination(res?.data?.links);
    setIsLoading(false);
  };

  return (
    <div className={`charging_control focus-in-expand-slow `}>
      <Row className="mb-3">
        <Col>
          <h2 className="text-white">Charging Control</h2>
        </Col>
        <Col>
          <SearchInput setSearchText={setSearchText} />
        </Col>
      </Row>
      <Row>
        <Col>
          <GridTable
            action={false}
            headers={tableHeaders}
            data={data}
            iteratorKeys={iteratorKeys}
            page={"users"}
            setRow={setRow}
            row={row}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            isLoading={isLoading}
          />
          <div className="page-table-pagination d-flex ms-auto justify-content-end">
            <Button
              disabled={pagination?.prev === 0 ? true : false}
              onClick={() => setPageNumber(pageNumber - 1)}
              className="paginated-prev"
            >
              <GrPrevious color="black" />
            </Button>
            <span className="text">
              {pagination?.current}/ {pagination?.total}
            </span>
            <Button
              disabled={
                pagination?.current === pagination?.total ? true : false
              }
              onClick={() => setPageNumber(pageNumber + 1)}
              className="paginated-forward"
            >
              <MdArrowForwardIos color="black" />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChargingControl;
