import React from "react";
// import { FaUserGroup } from 'react-icons/fa6'
import { HiOutlineUsers } from "react-icons/hi2";

const SupportSvgIcon = ({ color = "white" }) => {
  //     <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  // <path d="M4.97515 8.31245C4.97515 8.79458 5.0837 9.25133 5.27706 9.65733C4.86151 10.2068 4.60285 10.8885 4.56808 11.6313C3.77005 10.7668 3.27902 9.59783 3.27902 8.31333C3.27902 5.32869 5.92668 2.96968 8.92628 3.60493C10.6919 3.97944 12.1226 5.44682 12.4992 7.26595C12.6128 7.81545 12.634 8.35358 12.5763 8.86808C12.5272 9.30383 12.1566 9.62583 11.7308 9.62583H11.6927C11.1906 9.62583 10.8378 9.1647 10.8929 8.6502C10.9303 8.30458 10.9107 7.94145 10.8251 7.57132C10.5724 6.47495 9.70565 5.58682 8.64133 5.33394C6.70265 4.87456 4.9743 6.39007 4.9743 8.31333L4.97515 8.31245ZM1.67618 7.17932C1.90855 5.78544 2.56919 4.51494 3.59365 3.52356C4.89034 2.26968 6.58817 1.65018 8.36825 1.76393C11.7206 1.98793 14.342 5.01806 14.303 8.64583C14.2869 10.1648 13.0538 11.3741 11.5824 11.3741H9.54197C9.33504 10.6487 8.69221 10.115 7.92217 10.115C6.98506 10.115 6.22604 10.8981 6.22604 11.865C6.22604 12.8318 6.98506 13.615 7.92217 13.615C8.37843 13.615 8.79143 13.4268 9.09589 13.1241H11.5824C13.9731 13.1241 15.9652 11.1597 15.9991 8.69395C16.0619 4.12556 12.7358 0.301797 8.47765 0.018296C6.21841 -0.13308 4.07535 0.658798 2.43435 2.2478C1.1631 3.47718 0.317584 5.10469 0.0131292 6.85557C-0.0793097 7.38757 0.328609 7.87583 0.851864 7.87583C1.25215 7.87583 1.60918 7.58795 1.67618 7.1802V7.17932ZM7.94337 14.875C4.80978 14.875 2.09852 16.8805 1.19703 19.8642C1.0571 20.3254 1.30727 20.8162 1.7542 20.9606C2.20029 21.0998 2.67605 20.846 2.81598 20.3857C3.4851 18.172 5.59254 16.625 7.94252 16.625C10.2925 16.625 12.4008 18.172 13.0691 20.3857C13.1827 20.7603 13.5168 21 13.879 21C13.9629 21 14.0469 20.9869 14.1317 20.9606C14.5786 20.8162 14.828 20.3254 14.6889 19.8642C13.7874 16.8805 11.0761 14.875 7.94252 14.875H7.94337Z" fill={color}/>
  // </svg>

  return <HiOutlineUsers color={color} size={20} />;
};

export default SupportSvgIcon;
