import React from "react";

const DashValues = ({title, value}) => {
  return (
    <>
      <div className="text-white">
        <p className="m-0 fs-7">{title}</p>
        <p className="m-0 fs-2 fw-bold">{value}</p>
      </div>
    </>
  );
};

export default DashValues;
