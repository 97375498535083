import React, { useState } from "react";

import { ROUTES } from "./Route";

import ChargingControl from "./ChargingControl";

export default function USERS() {
  const [page, setPage] = useState(ROUTES.ChargingControl);

  switch (page) {
    case ROUTES.ChargingControl:
      return <ChargingControl setPage={setPage} />;

    default:
      return <ChargingControl setPage={setPage} />;
  }
}
