import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Button, Checkbox, Input } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { ACCOUNT_ENUM } from "./Route";

import { useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Logo from "../../Components/CommonComponents/Logo";
import { IMAGES } from "../../Constant/ImageConstant";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { RegisterPayload } from "../../Constant/PayloadConstant/RegisterPayloadConstant";
import { STRING } from "../../Constant/StringConstant";
import Toast from "../../Hooks/useToast";
import LoginLink from "./Components/LoginLink";
import RegisterYourself from "./Components/RegisterYourself";

export default function Register({ setPage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    // defaultValues:{
    //     email: '',
    //     password : '',
    // }
  });

  // Watch the password field value to compare with confirm password
  const password = watch("password");

  const handleLoginClick = () => setPage(ACCOUNT_ENUM.LOGIN);


  const handleRegister = async (data) => {
    // console.log('handle Register')
    // console.log(data)

    handleLoginClick();

    // try {

    //     const response = await registerUser(data);

    //     console.log(response)

    //     if (response.error && response.error.status === 400) {
    //         const message = await response.error.data.errors;
    //         Toast(`${message}`, "error");
    //         console.log(message)
    //     }

    //     if (!response.error && response.data.statusCode === 200) {
    //         // console.log(response.data.data)
    //         // Toast('You are registered successfully, Now Login with your credentials', "success");
    //         SuccessToast('You are registered successfully, Now Login with your credentials');
    //         handleLoginClick();
    //     }

    // } catch (error) {
    //     console.log(error)
    //     Toast('Something went wrong.', "error");

    // }
  };

  return (
    <AccountLayout leftSideImage={IMAGES.LOGIN}>
      {errors
        ? errors?.full_name?.message
          ? Toast(errors?.full_name?.message, "error")
          : errors?.email?.message
            ? Toast(errors?.email?.message, "error")
            : errors?.password?.message
              ? Toast(errors?.password?.message, "error")
              : errors?.confirm_password?.message
                ? Toast(errors?.confirm_password?.message, "error")
                : errors?.country?.message
                  ? Toast(errors?.country?.message, "error")
                  : errors?.agree?.message
                    ? Toast(errors?.agree?.message, "error")
                    : null
        : ""}

      <Logo />

      <RegisterYourself />

      <Form
        onSubmit={handleSubmit(handleRegister)}
        className="w-100 scale-in-center"
      >
        <Controller
          control={control}
          name={RegisterPayload.FullName}
          // rules={{
          //     required: STRING.FullNameRequired
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder={STRING.FullName}
                type={INPUT_TYPES.TEXT}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {
                // <div className="input-before-icon" >
                //     <CiUser />
                // </div>
              }
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={RegisterPayload.Username}
          // rules={{
          //     required: STRING.UniqueNameRequired
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder={STRING.WriteUserName}
                type={INPUT_TYPES.TEXT}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {
                // <div className="input-before-icon" >
                //     <CiUser />
                // </div>
              }
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={RegisterPayload.Email}
          // rules={{
          //     required: STRING.EmailRequired,
          //     pattern: {
          //         value: INPUT_PATTERNS.EMAIL_PATTERNS,
          //         message: STRING.ValidEmailRequired,
          //     },
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder={STRING.EmailAddress}
                type={INPUT_TYPES.EMAIL}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {
                // <div className="input-before-icon" >
                //     <AiOutlineMail />
                // </div>
              }
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={RegisterPayload.Password}
          // rules={{
          //     required: STRING.PasswordRequired,
          //     minLength: {
          //         value: 6,
          //         message: STRING.PasswordSixCharacterRequired,
          //     },
          //     pattern: {
          //         value: INPUT_PATTERNS.PASSWORD_PATTERNS,
          //         message: STRING.PasswordPatternRequired,
          //     },
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder={STRING.CreatePassword}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {
                // <div className="input-before-icon" >
                //     <PiKey />
                // </div>
              }
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={RegisterPayload.ConfirmPassword}
          // rules={{
          //     required: STRING.ConfirmPasswordRequired,
          //     minLength: {
          //         value: 6,
          //         message: STRING.ConfirmPasswordSixCharacterRequired,
          //     },
          //     pattern: {
          //         value: INPUT_PATTERNS.PASSWORD_PATTERNS,
          //         message: STRING.ConfirmPasswordPatternRequired,
          //     },
          //     validate: value => value === password || STRING.ConfirmPasswordMustMatch
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder={STRING.ConfirmPassword}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {
                // <div className="input-before-icon" >
                //     <PiKey />
                // </div>
              }
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name="agree"
          // rules={{
          //     required: 'Must be agree with Condition and Privacy Policy.'
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Checkbox
              label={STRING.ContinueWithSocialLogin}
              secondLabel={STRING.TermsAndCondition}
              classes="mt-2 mb-2"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />

        <Button
          classes="mt-4 mb-2 puff-in-hor"
          text={STRING.Register}
          type={INPUT_TYPES.SUBMIT}
        // onClick={handleSubmit}
        />
      </Form>

      <LoginLink handleLoginClick={handleLoginClick} />
    </AccountLayout>
  );
}
