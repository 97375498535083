import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import GridTable from "../../Components/DataGrid/DataGrid";
import SearchInput from "../../Components/Search/Search";
import { useState } from "react";
import { useEffect } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { GrPrevious } from "react-icons/gr";
import { getAllSupprts } from "../../services/support.service";

const tableHeaders = ["Title", "Description"];

const iteratorKeys = [
  (row) => {
    return `${row?.title || "-"}`;
  },
  (row) => {
    return `${row?.description || "-"}`;
  },
];

const User = ({ setPage }) => {
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [row, setRow] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    GetSupport();
  }, [pageNumber, searchText]);

  const GetSupport = async () => {
    setIsLoading(true);
    const params = {
      page: pageNumber,
      search: searchText,
    };
    let res = await getAllSupprts(params);
    setData(res?.data?.data);
    setPagination(res?.data?.links);
    setIsLoading(false);
  };

  return (
    <div className={`users focus-in-expand-slow `}>
      <Row className="mb-3">
        <Col>
          <h2 className="text-white">Support</h2>
        </Col>
        <Col>
          <SearchInput setSearchText={setSearchText} />
        </Col>
      </Row>
      <Row>
        <Col>
          <GridTable
            headers={tableHeaders}
            data={data}
            iteratorKeys={iteratorKeys}
            page={"Supprt"}
            action={false}
            isLoading={isLoading}
          />
          <div className="page-table-pagination d-flex ms-auto justify-content-end">
            <Button
              disabled={pagination?.prev === 0 ? true : false}
              onClick={() => setPageNumber(pageNumber - 1)}
              className="paginated-prev"
            >
              <GrPrevious color="black" />
            </Button>
            <span className="text">
              {pagination?.current}/ {pagination?.total}
            </span>
            <Button
              disabled={
                pagination?.current === pagination?.total ? true : false
              }
              onClick={() => setPageNumber(pageNumber + 1)}
              className="paginated-forward"
            >
              <MdArrowForwardIos color="black" />
            </Button>
          </div>
          {/* {data?.length === 0 && <p>No users found</p>} */}
        </Col>
      </Row>
    </div>
  );
};

export default User;

// import React, { useEffect, useState } from "react";
// import DesktopLayout from "./DesktopLayout";
// import { BREAKPOINTS } from "../../Constant/constants";
// import MobileLayout from "./MobileLayout";

// export default function Support() {

//     const [user, setUser] = useState('')
//     const [isGroup, setIsGroup] = useState(false);
//     const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.MOBILE);

//     useEffect(() => {
//         window.addEventListener('resize', handleWindowSizeChange);

//         return () => window.removeEventListener('resize', handleWindowSizeChange);
//     }, [])

//     function handleWindowSizeChange() {
//         setIsMobile(window.innerWidth <= BREAKPOINTS.MOBILE)
//     }

//     return (
//         isMobile ? <MobileLayout user={user} setUser={setUser} isGroup={isGroup} setIsGroup={setIsGroup} /> :
//             <DesktopLayout setUser={setUser} isGroup={isGroup} setIsGroup={setIsGroup} />
//     )
// }
