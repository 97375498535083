import React, { forwardRef } from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const CustomInput = forwardRef((props, ref) => {
  const {
    field,
    autoFocus = false,
    label = "",
    placeholder = "",
    type = "text",
    required = false,
    minLength = 0,
    maxLength = 255,
    value = "",
    onChange,
    disabled = false,
    className = "",
    as,
  } = props;

  return (
    <Form.Control
      {...field}
      type={type}
      autoComplete="off"
      placeholder={placeholder}
      minLength={minLength}
      maxLength={maxLength}
      value={value}
      as={as}
      className={className}
      disabled={disabled}
      onChange={(value) => onChange(value)}
      autoFocus={autoFocus}
    />
  );
});

export default CustomInput;
