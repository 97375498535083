import React, { useEffect, useState } from "react";
import ChartOptions from "./Components/ChartOptions";
import CustomChart from "./Components/CustomChart";
import DashValuesRow from "./Components/DashValuesRow";
import { getGrarphData } from "../../services/graph.service";

const Dashboard = ({ setPage }) => {
  const [firstOption, setFirstOption] = useState("monthly");
  const [selectedValue, setSelectedValue] = useState("monthly");
  const [monthlyData, setMonthlyData] = useState(null);
  const [weeklyData, setWeeklyData] = useState(null);
  const [data, setData] = useState(null);
  const optionList = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];

  const handleDaysChange = (value) => {
    // console.log(value);
    setFirstOption(value);
  };

  const firstRowData = [
    {
      title: "Total Users",
      value: data?.total_users,
    },
    {
      title: "Charging Sessions",
      value: data?.charging_sessions_count,
    },
    {
      title: "Recent transactions",
      value: data?.recent_transactions_amount.toFixed(1),
    },
  ];

  useEffect(() => {
    GetGraphData();
  }, []);

  const GetGraphData = async () => {
    let res = await getGrarphData();
    setMonthlyData(res?.data?.data?.graph_data_monthly);
    setWeeklyData(res?.data?.data?.graph_data_weekly);
    setData(res?.data?.data);
  };

  return (
    <div className={`Dashboard focus-in-expand-slow `}>
      <div className="card">
        <DashValuesRow data={firstRowData} />
      </div>
      <br></br>
      <div className="card">
        <ChartOptions
          firstOption={firstOption}
          optionList={optionList}
          handleDaysChange={handleDaysChange}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />

        <CustomChart
          data={firstOption === "weekly" ? weeklyData : monthlyData}
          selectedValue={selectedValue}
        />
      </div>
    </div>
  );
};

export default Dashboard;
