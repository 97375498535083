import * as React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ThemeSelect from "../../Components/ThemeSelect";
import { useState } from "react";
import { useEffect } from "react";
import CustomInput from "../../Components/ThemeInput";
import { getUserService } from "../../services/user.service";
import moment from "moment";

const AddTransaction = ({ onSubmit, btntext, row, isLoading }) => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    GetUserData();
  }, []);
  const GetUserData = async () => {
    let res = await getUserService();
    let option = res?.data?.data?.map((dt) => ({
      value: dt?.slug,
      label: dt?.first_name + " " + dt?.last_name,
    }));
    setData(option);
  };
  useEffect(() => {
    if (row) {
      setValue("description", row?.description);
      setValue("transaction_amount", row?.transaction_amount);
      setValue("user_slug", {
        value: row?.slug,
        label: row?.user?.first_name + " " + row?.user?.last_name,
      });
      setValue("transaction_type", {
        value: row?.transaction_type,
        label: row?.transaction_type === "debit" ? "Debit" : "Credit",
      });
      setValue("status", {
        value: row?.status,
        label: row?.status === "paid" ? "Paid" : "Un Paid",
      });
    }
  }, [row]);

  return (
    <div className="add-modal">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4">
          <ThemeSelect
            name="user_slug"
            control={control}
            errors={errors}
            disabled={row && true}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            label="Role"
            placeholder="Select"
            options={data}
          />
        </div>
        {/* <div className="mt-4">
          <ThemeSelect
            name="transaction_type"
            control={control}
            disabled={row && true}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            label="Select"
            placeholder="Select Type"
            options={[
              { value: "debit", label: "Debit" },
              { value: "credit", label: "Credit" },
            ]}
          />
        </div> */}
        <div className="mt-4">
          <ThemeSelect
            name="status"
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            label="Select"
            placeholder="Select Type"
            options={[
              { value: "paid", label: "Paid" },
              { value: "unpaid", label: "Un Paid" },
            ]}
          />
        </div>
        <div className="mt-3">
          <Controller
            control={control}
            name="transaction_amount"
            rules={{
              required: {
                value: true,
              },
            }}
            render={({ field }) => (
              <CustomInput
                {...field}
                label="Name"
                placeholder="Total Normal Hours"
                className="name"
                type="number"
                required={true}
                maxLength={10}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {errors.transaction_amount && (
            <small className="text-start d-block text-danger">
              {errors.transaction_amount.message}
            </small>
          )}
        </div>
        <div className="mt-3 text-area">
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <CustomInput
                {...field}
                label="Description"
                placeholder="Description"
                // className="name"
                type="text-area"
                as="textarea"
                className="name text-name"
                required={true}
                maxLength={100}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {errors.transaction_amount && (
            <small className="text-start d-block text-danger">
              {errors.transaction_amount.message}
            </small>
          )}
        </div>
        <div className="d-flex mt-3">
          <Button
            disabled={isLoading}
            type="submit"
            className="btn-solid btn-okay ms-auto"
          >
            {btntext}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddTransaction;
