import React from "react";
import { Navigate } from "react-router-dom";
import { WEB_ROUTES } from "../../Constant/constants";
import { baseRoute } from "../../Helper";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";

function ProtectedRoute({ children }) {
  const isLoggedIn = GetAuthUserLocalStorage();
  // var isLoggedIn = false;

  return isLoggedIn ? (
    children
  ) : (
    <Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} replace />
  );
}

export default ProtectedRoute;
