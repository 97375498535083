import React from "react";
import { FilterIcon, SearchIcon } from "./SvgIcons";

function SearchInput({ setSearchText }) {
  return (
    <div className="d-flex justify-content-end">
      <div className="search_container">
        <div className="px-2">
          <SearchIcon />
        </div>
        <div>
          <input
            type="text"
            className="search_input"
            placeholder="Search"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        {/* <div className="cursor-pointer filter_icon">
          <FilterIcon />
        </div> */}
      </div>
    </div>
  );
}

export default SearchInput;
