import React, { useState } from 'react';
import { ROUTES } from './Route';

import Dashboard from './Dashboard';


export default function DASHBOARD() {
    const [page, setPage] = useState(ROUTES.DASHBOARD);


    switch (page) {
        case ROUTES.DASHBOARD:
            return <Dashboard setPage={setPage} />

        default:
            return <Dashboard setPage={setPage} />
    }


}

