import React from 'react'

const DashboardSvgIcon = ({ color = "white" }) => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="8.52632" height="8.52632" rx="1.89474" stroke={color} stroke-width="1.4" />
            <rect x="1" y="11.4737" width="8.52632" height="8.52632" rx="1.89474" stroke={color} stroke-width="1.4" />
            <rect x="11.4727" y="1" width="8.52632" height="8.52632" rx="1.89474" stroke={color} stroke-width="1.4" />
            <rect x="11.4727" y="11.4736" width="8.52632" height="8.52632" rx="1.89474" stroke={color} stroke-width="1.4" />
        </svg>

    )
}

export default DashboardSvgIcon
