import React, { useState } from "react";
import { ACCOUNT_ENUM } from "./Route";

import { useDispatch } from "react-redux";
import { Form, InputGroup } from "react-bootstrap";

// Custom Component
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { Button, Description, Input } from "../../Components/CustomComponents";

// import { useGetAllUsersQuery } from "../../services/userApi";
// import { updateUsers } from "../../Features/userSlice";
import { useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Toast, { error, success, SuccessToast } from "../../Hooks/useToast";
import { WEB_ROUTES } from "../../Constant/constants";
import { IMAGES } from "../../Constant/ImageConstant";
import { STRING } from "../../Constant/StringConstant";
import {
  INPUT_PATTERNS,
  INPUT_TYPES,
} from "../../Constant/InputTypesAndPatternsConstant";

import Logo from "../../Components/CommonComponents/Logo";
import SocialButtons from "./Components/SocialButtons";
import WelcomeBack from "./Components/WelcomeBack";
import SignUpLink from "./Components/SignUpLink";
import { LoginPayload } from "../../Constant/PayloadConstant/LoginPayloadConstant";
import { SetAuthUserLocalStorage, SetTokenLocalStorage } from "../../services/localStorage/localStorage";
import { LoginService } from "../../services/auth.service";

export default function Login({ setPage }) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // defaultValues:{
    //     email: '',
    //     password : '',
    // }
  });

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleSignUpClick = () => {
    setPage(ACCOUNT_ENUM.REGISTER);
  };



  // const handleLogin = async (data) => {
  //   console.log("handle Login");
  //   console.log(data);

  //   // todo: for Alpha
  //   // storeToken("testing_token");
  //   navigate(WEB_ROUTES.DASHBOARD);

  //   // try {

  //   //     const response = await loginUser(data);

  //   //     // console.log(response)

  //   //     if (response.error && response.error.status === 400) {
  //   //         const message = await response.error.data.errors;
  //   //         Toast(`${message}`, "error");
  //   //         // console.log(message)
  //   //     }

  //   //     if (!response.error && response.data.statusCode === 200) {

  //   //         // console.log(response)
  //   //         // console.log(response.data.data.token[0].api_token)
  //   //         // console.log(response.data.data.email)
  //   //         // console.log(response.data.data.full_name)

  //   //         const token = response.data.data.token[0].api_token;
  //   //         const email = response.data.data.email;
  //   //         const name = response.data.data.full_name;

  //   //         dispatch(setAuthInfo({
  //   //             token: token,
  //   //             email: email,
  //   //             name: name,
  //   //             isLoggedIn: true,
  //   //             keepLoggedIn: '',
  //   //         }));

  //   //         storeToken(token);
  //   //         SuccessToast(response?.data?.message)
  //   //         navigate(WEB_ROUTES.DASHBOARD)
  //   //     }

  //   // } catch (error) {

  //   //     console.log(error)
  //   //     Toast('Something went wrong.', "error");

  //   // }
  // };

  const handleLogin = async (data) => {
    setLoading(true)
    data["device_type"] = "web"
    data["device_token"] = "ABC 123"

    try {
      const res = await LoginService(data)

      success(res?.data?.message)
      navigate('/dashboard')
      SetAuthUserLocalStorage(res?.data?.data)
      SetTokenLocalStorage(res?.data?.data?.api_token)
      setLoading(false)
    } catch (e) {
      error(e?.response?.data?.message);
      setLoading(false)
    }
  }

  return (
    <AccountLayout leftSideImage={IMAGES.LOGIN}>
      {errors
        ? errors?.email?.message
          ? Toast(errors?.email?.message, "error")
          : errors?.password?.message
            ? Toast(errors?.password?.message, "error")
            : null
        : ""}

      <Logo />

      <WelcomeBack />

      <Form
        className="w-100 scale-in-center"
        onSubmit={handleSubmit(handleLogin)}
      >
        <Controller
          control={control}
          name={LoginPayload.Email}
          // rules={{
          //     required: STRING.EmailRequired,
          //     pattern: {
          //         value: INPUT_PATTERNS.EMAIL_PATTERNS,
          //         message: STRING.ValidEmailRequired,
          //     },
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder={STRING.EmailAddress}
                type={INPUT_TYPES.EMAIL}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {
                // <div className="input-before-icon" >
                //     <CiUser />
                // </div>
              }
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={LoginPayload.Password}
          // rules={{
          //     required: STRING.PasswordRequired,
          //     minLength: {
          //         value: 6,
          //         message: STRING.PasswordSixCharacterRequired,
          //     },
          //     pattern: {
          //         value: INPUT_PATTERNS.PASSWORD_PATTERNS,
          //         message: STRING.PasswordPatternRequired,
          //     },
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder={STRING.Password}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <div className="w-100 d-flex justify-content-between align-items-center">
          {
            //     <Checbox
            //     label='Keep me login'
            //     classes="mt-2 mb-2 "
            // />
          }
          <Description
            classes="ms-auto cursor-pointer"
            text={STRING.ForgetPassword}
            onClick={handleForgotPasswordClick}
          />
        </div>

        <br></br>

        <Button
          classes="mt-3 mb-2 scale-in-center-slow"
          text={STRING.Login}
          onClick={handleSubmit}
          type={INPUT_TYPES.SUBMIT}
          disabled={loading}
        />
      </Form>

    </AccountLayout>
  );
}
