import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../ThemeInput";
import TimeFieldComponent from "../CustomComponents/TimeFieldComponent";
import ThemeSelect from "../ThemeSelect";
import { useEffect } from "react";
import { getRegions } from "../../services/regions.service";
import moment from "moment";

const AddEnergyRates = ({
  onSubmit,
  btntext,
  setStartTime,
  setEndTime,
  startTime,
  endTime,
  row,
  isLoading,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [data, setData] = useState([]);

  useEffect(() => {
    GetRegions();
  }, []);

  useEffect(() => {
    if (row) {
      setValue("name", row?.name);
      setValue("normal_hour_charging_rate", row?.normal_hour_charging_rate);
      setValue(
        "normal_hour_discharging_rate",
        row?.normal_hour_discharging_rate
      );
      setValue("peak_hour_charging_rate", row?.peak_hour_charging_rate);
      setValue("peak_hour_discharging_rate", row?.peak_hour_discharging_rate);
      setValue("region", {
        value: row?.region?.slug,
        label: row?.region?.name,
      });
      setStartTime(moment(row?.peak_hour_start_time, "HH:mm:ss"));
      setEndTime(moment(row?.peak_hour_end_time, "HH:mm:ss"));
    }
  }, [row]);
  const GetRegions = async () => {
    let res = await getRegions();
    let option = res?.data?.data?.map((dt) => ({
      value: dt?.slug,
      label: dt?.name,
    }));
    setData(option);
  };
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  return (
    <>
      <div className="add-modal">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <Controller
              control={control}
              name="name"
              rules={{
                required: {
                  value: true,
                },
                maxLength: {
                  value: 100,
                },
              }}
              render={({ field }) => (
                <CustomInput
                  {...field}
                  label="Name"
                  placeholder="Name"
                  className="name"
                  type="text"
                  required={true}
                  maxLength={100}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.name && (
              <small className="text-start d-block text-danger">
                {errors.name.message}
              </small>
            )}
          </div>
          <div className="charging-rate mt-3">Charging Rate</div>
          <Row>
            <Col>
              <div className="mt-3">
                <Controller
                  control={control}
                  name="peak_hour_charging_rate"
                  rules={{
                    required: {
                      value: true,
                    },
                  }}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Name"
                      placeholder="Peak Rate"
                      className="name"
                      type="number"
                      required={true}
                      maxLength={10}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                {errors.peak_hour_charging_rate && (
                  <small className="text-start d-block text-danger">
                    {errors.peak_hour_charging_rate.message}
                  </small>
                )}
              </div>
            </Col>
            <Col>
              <div className="mt-3">
                <Controller
                  control={control}
                  name="normal_hour_charging_rate"
                  rules={{
                    required: {
                      value: true,
                    },
                  }}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Name"
                      placeholder="Normal Rate"
                      className="name"
                      type="number"
                      required={true}
                      maxLength={10}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                {errors.normal_hour_charging_rate && (
                  <small className="text-start d-block text-danger">
                    {errors.normal_hour_charging_rate.message}
                  </small>
                )}
              </div>
            </Col>
          </Row>
          <div className="charging-rate mt-3">Discharging Rate</div>
          <Row>
            <Col>
              <div className="mt-3">
                <Controller
                  control={control}
                  name="peak_hour_discharging_rate"
                  rules={{
                    required: {
                      value: true,
                    },
                  }}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Name"
                      placeholder="Peak Rate"
                      className="name"
                      type="number"
                      required={true}
                      maxLength={10}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                {errors.peak_hour_discharging_rate && (
                  <small className="text-start d-block text-danger">
                    {errors.peak_hour_discharging_rate.message}
                  </small>
                )}
              </div>
            </Col>
            <Col>
              <div className="mt-3">
                <Controller
                  control={control}
                  name="normal_hour_discharging_rate"
                  rules={{
                    required: {
                      value: true,
                    },
                  }}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Name"
                      placeholder="Normal Rate"
                      className="name"
                      type="number"
                      required={true}
                      maxLength={10}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                {errors.normal_hour_discharging_rate && (
                  <small className="text-start d-block text-danger">
                    {errors.normal_hour_discharging_rate.message}
                  </small>
                )}
              </div>
            </Col>
          </Row>
          <div className="mt-4">
            <ThemeSelect
              name="region"
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
              }}
              label="Role"
              placeholder="Select your Region"
              options={data}
            />
          </div>
          <Row>
            <Col className="mt-3">
              <TimeFieldComponent
                value={startTime}
                placeholder={"Pick start time"}
                onChange={(e) => setStartTime(e)}
              />
            </Col>
            <Col className="mt-3">
              <TimeFieldComponent
                value={endTime}
                placeholder={"Pick end time"}
                onChange={(e) => setEndTime(e)}
              />
            </Col>
          </Row>
          <div className="d-flex mt-3">
            <Button disabled={isLoading} type="submit" className="btn-solid btn-okay ms-auto">
              {btntext}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddEnergyRates;
