import React from "react";
import SelectFieldComponent from "../../../Components/CustomComponents/SelectFieldComponent.js";

const ChartOptions = ({
  firstOption,
  optionList,
  selectedValue,
  setSelectedValue,
  handleDaysChange,
}) => {
  return (
    <>
      <div className="d-flex flex-row flex-wrap justify-content-center justify-content-md-between gap-sm-5 gap-2 align-items-center text-white">
        <div className="me-auto">
          <p className="m-0 fs-4 fw-bold">Total Revenue</p>
        </div>
        <div className="d-flex align-items-center gap-3">
          {/* <span className="blue-dot"></span>
          <p className="m-0 fs-6">Weekends</p> */}
          <SelectFieldComponent
            firstOption={firstOption}
            optionsList={optionList}
            onSelectChange={handleDaysChange}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
        </div>
      </div>
    </>
  );
};

export default ChartOptions;
