import api from "./Authindex";
import { apiUrl, constant } from "../Constant/constants";
// import { apiUrl, constant } from "../../utils/constants";

export const LoginService = (data) =>
  api.post(`${constant.VER}${constant.admin}${apiUrl.login}`, data);
export const ForgotService = (data) =>
  api.post(`${constant.VER}${constant.admin}${apiUrl.forget_password}`, data);
export const ResetPassService = (data) =>
  api.post(`${constant.VER}${constant.admin}${apiUrl.reset_password}`, data);
