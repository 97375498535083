import React from "react";
import { Col, Row } from "react-bootstrap";
import DashValues from "./DashValues";

const DashValuesRow = ({data}) => {
  return (
    <>
      <Row className="gap-2 gap-lg-0">
        <Col sm={12} md={12} lg={4}>
          <div className="d-flex flex-column flex-wrap flex-lg-row align-items-center justify-content-center justify-content-lg-start scale-up-center-slow">
            <DashValues title={data[0].title} value={data[0].value} />
          </div>
        </Col>
        <Col sm={12} md={12} lg={4} className="right-border">
          <div className="d-flex flex-column flex-wrap flex-lg-row align-items-center justify-content-center scale-up-center ">
            <DashValues title={data[1].title} value={data[1].value} />
          </div>
        </Col>
        <Col sm={12} md={12} lg={4} className="right-border">
          <div
            className="d-flex flex-column flex-wrap flex-lg-row align-items-center justify-content-center justify-content-lg-end scale-up-center"
            style={{ animationDuration: "1.2s" }}
          >
            <DashValues title={data[2].title} value={data[2].value} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DashValuesRow;
