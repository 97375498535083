import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import GridTable from "../../Components/DataGrid/DataGrid";
import SearchInput from "../../Components/Search/Search";
import {
  addTransaction,
  deleteTransaction,
  getAllTransaction,
  updateTransaction,
} from "../../services/transaction.service";
import { useState } from "react";
import { useEffect } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { GrPrevious } from "react-icons/gr";
import { set, toInteger } from "lodash";
import ThemeModal from "../../Components/modals/ThemeModal";
import { error, success } from "../../Hooks/useToast";
import AddTransaction from "../../Components/modals/addTransaction";

const tableHeaders = [
  "User Name",
  "Status",
  "Amount",
  "Type",
  "Description",
  "Action",
];

const iteratorKeys = [
  (row) => {
    return row?.user?.first_name + " " + row?.user?.last_name;
  },
  "status",
  "transaction_amount",
  "transaction_type",
  "description",
];

const User = ({ setPage }) => {
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const [row, setRow] = useState({});
  console.log(data);

  useEffect(() => {
    GetAllTransaction();
  }, [pageNumber, searchText]);

  const GetAllTransaction = async () => {
    const params = {
      page: pageNumber,
      search: searchText,
    };
    let res = await getAllTransaction(params);
    setData(res?.data?.data);
    setPagination(res?.data?.links);
  };

  const handleDelete = async () => {
    setAnchorEl(null);
    setDeleteModal(true);
  };
  const handleEdit = async () => {
    setAnchorEl(null);
    setEditModal(true);
  };

  const onSubmitDeleteModal = async () => {
    if (!row || !row.slug) {
      error("Row data is missing or slug is undefined");
      return;
    }

    let slug = row.slug;
    try {
      await deleteTransaction(slug);
      GetAllTransaction();
    } catch (e) {
      error(e?.response?.data?.message || "An error occurred");
    } finally {
      setAnchorEl(null);
      setDeleteModal(false);
    }
  };
  const onSubmitAddModal = async (data) => {
    setAddLoading(true);
    data["user_slug"] = data?.user_slug?.value;
    data["transaction_type"] = "debit";
    data["status"] = data?.status?.value;
    data["transaction_amount"] = parseInt(data?.transaction_amount);
    console.log(data);

    try {
      const res = await addTransaction(data);
      success(res?.data?.message);
      setAddModal(false);
      setAddLoading(false);
      GetAllTransaction();
    } catch (e) {
      error(e.response.data.message);
      setAddLoading(false);
    }
  };

  const onSubmitEditModal = async (data) => {
    setEditLoading(true);
    data["user_slug"] = row?.user_slug;
    data["transaction_type"] = "debit";
    data["status"] = data?.status?.value;
    console.log(data, "after");

    if (!row || !row.slug) {
      error("Row data is missing or slug is undefined");
      return;
    }

    let slug = row.slug;
    try {
      let res = await updateTransaction(slug, data);
      setEditModal(false);
      setEditLoading(false);
      success(res?.data?.message);
      GetAllTransaction();
    } catch (e) {
      error(e?.response?.data?.message || "An error occurred");
    } finally {
      setAnchorEl(null);
      setEditLoading(false);
    }
  };

  return (
    <div className={`users focus-in-expand-slow `}>
      <Row className="mb-3">
        <Col>
          <h2 className="text-white">Transactions</h2>
        </Col>
        <Col>
          <div className="d-flex align-items-center justify-content-end gap-3">
            <div className="plus_btn" onClick={() => setAddModal(true)}>
              <PlusSign /> <div>Add</div>
            </div>
            <SearchInput setSearchText={setSearchText} />
          </div>
        </Col>
      </Row>

      {/* <Row className="mb-3">
        <Col>
          <h2 className="text-white">Transactions</h2>
        </Col>
        <Col>
          <SearchInput setSearchText={setSearchText} />
        </Col>
      </Row> */}
      <Row>
        <Col>
          <GridTable
            headers={tableHeaders}
            data={data}
            iteratorKeys={iteratorKeys}
            page={"transactions"}
            row={row}
            anchorEl={anchorEl}
            handleDelete={handleDelete}
            setAnchorEl={setAnchorEl}
            setRow={setRow}
            handleEdit={handleEdit}
          />
          <div className="page-table-pagination d-flex ms-auto justify-content-end">
            <Button
              disabled={pagination?.prev === 0 ? true : false}
              onClick={() => setPageNumber(pageNumber - 1)}
              className="paginated-prev"
            >
              <GrPrevious color="black" />
            </Button>
            <span className="text">
              {pagination?.current}/ {pagination?.total}
            </span>
            <Button
              disabled={
                pagination?.current === pagination?.total ? true : false
              }
              onClick={() => setPageNumber(pageNumber + 1)}
              className="paginated-forward"
            >
              <MdArrowForwardIos color="black" />
            </Button>
          </div>
        </Col>
      </Row>
      <ThemeModal
        setState={setDeleteModal}
        initialState={deleteModal}
        onSubmitModal={onSubmitDeleteModal}
        cancelText={"Cancel"}
        deletetext={"Confirm"}
        isRed
        heading={"Are you sure you want to Delete?"}
        Text={"Click Confirm to Delete"}
      />
      <ThemeModal
        setState={setAddModal}
        initialState={addModal}
        children={
          <AddTransaction
            onSubmit={onSubmitAddModal}
            btntext={"Save"}
            isLoading={addLoading}
          />
        }
        // cancelText={"Cancel"}
        heading={"Add Details"}
        // Text={"Click Confirm to log out"}
      />
      <ThemeModal
        setState={setEditModal}
        initialState={editModal}
        children={
          <AddTransaction
            onSubmit={onSubmitEditModal}
            row={row}
            isLoading={editLoading}
            btntext={"save"}
          />
        }
        heading={"Edit Details"}
        // Text={"Click Confirm to log out"}
      />
    </div>
  );
};

export default User;
const PlusSign = () => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.04 4.214V5.686H5.946V9.78H4.474V5.686H0.38V4.214H4.474V0.12H5.946V4.214H10.04Z"
        fill="#BFBFBF"
      />
    </svg>
  );
};
