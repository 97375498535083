import React from "react";
import moment from "moment";

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
const CustomChart = ({ data, selectedValue }) => {
  console.log(data, "data.length");

  // function getIntroOfPage(label) {
  //   if (label === "Jan") {
  //     return "Jan";
  //   }
  //   if (label === "Feb") {
  //     return "Feb";
  //   }
  //   if (label === "Mar") {
  //     return "Mar ";
  //   }
  //   if (label === "April") {
  //     return "April";
  //   }
  //   if (label === "May") {
  //     return "May";
  //   }
  //   if (label === "Jun") {
  //     return "Jun";
  //   }
  // }

  function CustomTooltip({ payload, label, active }) {
    const value = parseInt(payload[0]?.value);
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label m-0">{`${label}`}</p>
          <p className="value m-0">{`Rs. ${value.toFixed(2)}`}</p>
          {/* <p className="intro">{getIntroOfPage(label)}</p> */}
          {/* <p className="desc">Anything you want can be displayed here.</p> */}
        </div>
      );
    }

    return null;
  }
  const formattedMonths = (date) => {
    return moment(date, "YYYY-MM").format("MMMM");
  };

  const formattedDate = (date) => {
    return moment(date, "YYYY-MM-DD").format("MMMM");
  };

  return (
    <>
      <ResponsiveContainer>
        <LineChart width={730} height={250} data={data}>
          <XAxis
            dataKey={selectedValue === "monthly" ? "month" : "day"}
            tickFormatter={(tick) =>
              selectedValue === "monthly"
                ? formattedMonths(tick)
                : formattedDate(tick)
            }
            tick={{ stroke: "#7C8DB5", strokeWidth: 0.1 }}
          />
          <YAxis tick={{ stroke: "#7C8DB5", strokeWidth: 0.1 }} />
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid stroke="#f5f5f5" vertical={false} strokeWidth={0.1} />
          <Line
            type="monotone"
            dataKey="total_amount"
            stroke="#4D5DFA"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default CustomChart;
