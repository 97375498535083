import React, { useState } from "react";

import { ROUTES } from "./Route";

import EnergyRates from "./EnergyRates";

export default function USERS() {
  const [page, setPage] = useState(ROUTES.EnergyRates);

  switch (page) {
    case ROUTES.EnergyRates:
      return <EnergyRates setPage={setPage} />;

    default:
      return <EnergyRates setPage={setPage} />;
  }
}
