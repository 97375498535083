import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  value: null,
};

export const forgotEmailSlice = createSlice({
  name: "forgotemail",
  initialState,
  reducers: {
    setForgotEmail: (state, action) => {
      state.forgotemail = action.payload;
    },
  },
});

export const { setForgotEmail } = forgotEmailSlice.actions;
export const forgotEmailReducer = forgotEmailSlice.reducer;
