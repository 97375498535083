import React, { useState } from "react";
import filterStyles from "./selectFieldComponent.module.css";

const SelectFieldComponent = ({
  optionsList,
  firstOption,
  onSelectChange,
  style,
  disabled = false,
  className,
  selectedValue,
  setSelectedValue,
}) => {

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onSelectChange(value); // Send selected value to parent
  };

  return (
    <div style={{ width: "100%" }}>
      <select
        style={style}
        className={className ? className : `${filterStyles.select}`}
        value={selectedValue}
        onChange={handleSelectChange}
        disabled={disabled}
      >
        <option value="" selected disabled hidden>
          {firstOption}
        </option>
        {optionsList?.map((dt, index) => (
          <option value={dt?.value} key={index}>
            {dt?.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectFieldComponent;
