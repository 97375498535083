export const WEB_ROUTES = {
  ACCOUNT: "account",
  DASHBOARD: "dashboard",
  UserListing: "user-listing",
  Transactions: "transactions",
  ChargingControl: "charging-control",
  EnergyRates: "energy-rates",
  Settings: "settings",
  Support: "support",
  Calculation: "rate-calculation",
};

export const BREAKPOINTS = {
  MOBILE: 991,
};

export const constant = {
  LOCAL_STORAGE_TOKEN: "fluxadmin-token",
  LOCAL_STORAGE_USER: "fluxadmin-user",
  LOCAL_STORAGE_ROLE: "fluxadmin-role",
  LOCAL_STORAGE_EMAIL_SIGNUP: "signup-email",
  BASE_URL: process.env.REACT_APP_API_DOMAIN,
  VER: "/api",
  admin: "/admin",
};
export const apiUrl = {
  login: "/login",
  signup: "/register",
  verify_otp: "/verify-otp",
  resend_otp: "/resend-otp",
  forget_password: "/forgot-password",
  reset_password: "/reset-password",
  logout: "/logout",
  contact_us: "/contact-us",
  // dropdown
  dropdown: "/general/dropdown",
  // createProfile
  user: "/users",
  message: "/message",
  createProfile: "/complete-profile",
  upload_image: "/general/image-upload",
  //
  matches: "/matches",
  // addtofav
  addtofav: "/add-to-favourites",
  // fav matches
  mymatches: "/get-favourite-profiles",
  project: "project",
  vendors: "vendors",
  send_invitation: "/project/send-invitation",
  // payment
  payment: "/payment",
  payment_subscribe_plan: "/payment/subscribe-plan",
  addCard: "/add-card",
  subscriptionPlan: "/subscription-plan",
  transaction: "/transaction",
  uservehicle: "/user-vehicle",
  company: "/company",
  region: "/region",
  adminquery: "/admin-query",
  Calculaterates: "/calculate-rates",
  dashboardstatistics: "/dashboard-statistics",
};
