import React from 'react'

const ChargingControlSvgIcon = ({ color = "white" }) => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 4.34376C21 2.40999 19.4303 0.83737 17.5 0.83737C15.5697 0.83737 14 2.40999 14 4.34376C14 5.97423 15.1174 7.34874 16.625 7.73883V13.1097C16.625 14.5596 15.4473 15.7395 14 15.7395H11.0521L12.9797 13.8084C13.3219 13.4656 13.3219 12.9116 12.9797 12.5689C12.6376 12.2261 12.0846 12.2261 11.7425 12.5689L9.51912 14.7963C9.03875 15.2767 8.75 15.7395 8.75 16.6161C8.75 17.4927 9.00987 18.0055 9.51912 18.5157L11.7425 20.7432C11.9131 20.9141 12.1371 21 12.3611 21C12.5851 21 12.8091 20.9141 12.9797 20.7432C13.3219 20.4004 13.3219 19.8464 12.9797 19.5036L10.9734 17.4936H14C16.4124 17.4936 18.375 15.5274 18.375 13.1106V7.7397C19.8826 7.34962 21 5.97423 21 4.34376ZM17.5 6.09696C16.5349 6.09696 15.75 5.31065 15.75 4.34376C15.75 3.37687 16.5349 2.59057 17.5 2.59057C18.4651 2.59057 19.25 3.37687 19.25 4.34376C19.25 5.31065 18.4651 6.09696 17.5 6.09696ZM11.5535 6.2039C12.0496 5.70687 12.3226 5.04679 12.3226 4.34376C12.3226 3.64073 12.0496 2.98065 11.5535 2.4845L9.33012 0.257062C8.988 -0.0856875 8.435 -0.0856875 8.09287 0.257062C7.75075 0.599812 7.75075 1.15382 8.09287 1.49657L10.059 3.46716H7C4.58763 3.46716 2.625 5.43337 2.625 7.85015V13.2211C1.11737 13.6112 0 14.9857 0 16.6161C0 18.5499 1.56975 20.1225 3.5 20.1225C5.43025 20.1225 7 18.5499 7 16.6161C7 14.9857 5.88263 13.6112 4.375 13.2211V7.85015C4.375 6.40026 5.55275 5.22036 7 5.22036H10.0599L8.09287 7.19095C7.75075 7.5337 7.75075 8.08771 8.09287 8.43046C8.2635 8.6014 8.4875 8.68731 8.7115 8.68731C8.9355 8.68731 9.1595 8.6014 9.33012 8.43046L11.5535 6.2039ZM5.25 16.6161C5.25 17.583 4.46513 18.3693 3.5 18.3693C2.53487 18.3693 1.75 17.583 1.75 16.6161C1.75 15.6492 2.53487 14.8629 3.5 14.8629C4.46513 14.8629 5.25 15.6492 5.25 16.6161Z" fill={color} />
        </svg>


    )
}

export default ChargingControlSvgIcon
