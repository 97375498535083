import React from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { BackButton, Button, Input } from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Toast, { error, success, SuccessToast } from "../../Hooks/useToast";
import { IMAGES } from "../../Constant/ImageConstant";
import Logo from "../../Components/CommonComponents/Logo";
import SetNewPasswordHeading from "./Components/SetNewPasswordHeading";
import { SetNewPasswordPayload } from "../../Constant/PayloadConstant/SetNewPasswordPayloadConstant";
import { STRING } from "../../Constant/StringConstant";
import {
  INPUT_PATTERNS,
  INPUT_TYPES,
} from "../../Constant/InputTypesAndPatternsConstant";
import SetNewPasswordInstructions from "./Components/SetNewPasswordInstructions";
import { ForgotService, ResetPassService } from "../../services/auth.service";
import OtpInput from "react-otp-input";
import { useState } from "react";

export default function SetPassword({ setPage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const forgotemail = useSelector(
    (state) => state.forgotemail.forgotemail.email
  );
  console.log(forgotemail);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    // defaultValues:{
    //     email: '',
    //     password : '',
    // }
  });
  // Watch the password field value to compare with confirm password
  const new_password = watch("new_password");

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleGotoLogin = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const handleChangePassword = async (data) => {
    data["email"] = forgotemail;
    data["otp"] = otp;
    try {
      const res = await ResetPassService(data);
      success(res?.data?.message);
      handleGotoLogin();
    } catch (e) {      
      error(e?.response?.data?.message);
    }
  };

  return (
    <AccountLayout
      leftSideImage={IMAGES.PASSWORD}
      hasBackButton={false}
      handleBackButton={handleBackButtonClick}
    >
      {errors
        ? errors?.new_password?.message
          ? Toast(errors?.new_password?.message, "error")
          : errors?.confirmed_password?.message
          ? Toast(errors?.confirmed_password?.message, "error")
          : null
        : ""}

      <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />
      <Logo />

      <SetNewPasswordHeading />

      <Form onSubmit={handleSubmit(handleChangePassword)} className="w-100">
        <Controller
          control={control}
          name={SetNewPasswordPayload.NewPassword}
          // rules={{
          //     required: STRING.NewPasswordRequired,
          //     minLength: {
          //         value: 6,
          //         message: STRING.PasswordSixCharacterRequired,
          //     },
          //     pattern: {
          //         value: INPUT_PATTERNS.PASSWORD_PATTERNS,
          //         message: STRING.PasswordPatternRequired,
          //     },
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-1"
                placeholder={STRING.SetNewPassword}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {
                // <div className="input-before-icon">
                //     <PiKey />
                // </div>
              }
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={SetNewPasswordPayload.ConfirmedPassword}
          // rules={{
          //     required: STRING.Confirm2PasswordRequired,
          //     minLength: {
          //         value: 6,
          //         message: STRING.ConfirmPasswordSixCharacterRequired,
          //     },
          //     pattern: {
          //         value: INPUT_PATTERNS.PASSWORD_PATTERNS,
          //         message: STRING.ConfirmPasswordPatternRequired,
          //     },
          //     validate: value => value === new_password || STRING.ConfirmPasswordMustMatch
          // }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-1"
                placeholder={STRING.ConfirmPassword}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {
                //     <div className="input-before-icon">
                //     <PiKey />
                // </div>
              }
            </InputGroup>
          )}
        />
        <div className="mt-1">
          <div
            className="otp"
            style={{
              color: "white",
              fontWeight: 300,
              fontSize: "20px",
              padding: "10px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            OTP
          </div>
          <Controller
            control={control}
            name={"otp"}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                placeholder="OTP"
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: "100%",
                  backgroundColor: "#1A232D",
                  border: "none",
                  color: "white",
                  height: "40px",
                }}
              />
            )}
          />
        </div>

        <br></br>

        <SetNewPasswordInstructions />

        <Button
          classes="mt-4 mb-2"
          text={STRING.Continue}
          type={INPUT_TYPES.SUBMIT}
          // onClick={handleSubmit}
        />
      </Form>
    </AccountLayout>
  );
}
