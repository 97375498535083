import React, { useMemo } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

// global scss for globally styles and fonts
// import './_fonts.scss';
// import './_global.scss';

import PublicRoute from "./Components/Routes/PublicRoute";
import { WEB_ROUTES } from "./Constant/constants";
import { baseRoute } from "./Helper";

import ProtectedLayout from "./Components/Layouts/ProtectedLayout";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";

import { uniqueId } from "lodash";
import { STRING } from "./Constant/StringConstant";

import Account from "./Pages/Account";
import ChargingControl from "./Pages/ChargingControl";
import Dashboard from "./Pages/Dashboard";
import EnergyRates from "./Pages/EnergyRates";
import Support from "./Pages/Support";
import Transaction from "./Pages/Transaction";
import UserListing from "./Pages/User";
import RateCalculations from "./Pages/RateCalculation/RateCalculation";

function App() {
  // todo: menu
  const ROUTES = useMemo(() => {
    return [
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.ACCOUNT)}
        element={
          <PublicRoute>
            <Account />
          </PublicRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.DASHBOARD)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              title={STRING.Dashboard}
              isNavBar={true}
              selectedRoute={0}
            >
              <Dashboard />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.UserListing)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              title={STRING.UserListing}
              isNavBar={true}
              selectedRoute={1}
            >
              <UserListing />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.Transactions)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              title={STRING.Transactions}
              isNavBar={true}
              selectedRoute={2}
            >
              <Transaction />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.ChargingControl)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              title={STRING.ChargingControl}
              isNavBar={true}
              selectedRoute={3}
            >
              <ChargingControl />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.EnergyRates)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              title={STRING.EnergyRates}
              isNavBar={true}
              selectedRoute={4}
            >
              <EnergyRates />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.Calculation)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              title={STRING.RateCalculation}
              isNavBar={true}
              selectedRoute={5}
            >
              <RateCalculations />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,

      // <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.Settings)} element={
      //   <ProtectedRoute>
      //     <ProtectedLayout
      //       title={STRING.Settings}
      //       isNavBar={true}
      //       selectedRoute={5}>
      //       <Dashboard />
      //     </ProtectedLayout>
      //   </ProtectedRoute>
      // } />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.Support)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              title={STRING.Support}
              isNavBar={true}
              selectedRoute={6}
            >
              <Support />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
    ];
  }, []);

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route
          path={baseRoute("*")}
          element={<Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
