import React, { useState } from "react";

import { ROUTES } from "./Route";

import TransactionList from "./Transaction";

export default function Transaction() {
  const [page, setPage] = useState(ROUTES.TRANSACTION);

  switch (page) {
    case ROUTES.TRANSACTION:
      return <TransactionList setPage={setPage} />;

    default:
      return <TransactionList setPage={setPage} />;
  }
}
