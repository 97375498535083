import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import { forgotEmailReducer } from "./Slices/AuthSlice";
import persistConfig from "./persistConfig";

const rootReducer = combineReducers({
  forgotemail: forgotEmailReducer,
  // Add other reducers here
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
