import React, { useState } from "react";

import { ROUTES } from "./Route";

import User from "./User";

export default function USERS() {
  const [page, setPage] = useState(ROUTES.USER);

  switch (page) {
    case ROUTES.USER:
      return <User setPage={setPage} />;

    default:
      return <User setPage={setPage} />;
  }
}
