import React, { useEffect, useState } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";

/**Import Assets */
import styles from "./styles/ProtectedLayout.module.scss";
// import cssStyles from './styles/notification.module.css';

/**Import Custom Component */
import Navbar from "./Navbar";

import { useDispatch } from "react-redux";
// import FilterModal from "../Modals/FilterModal";
// Icons
import { STRING } from "../../Constant/StringConstant";
import DashboardSvgIcon from "./SVG_Icons/DashboardSvgIcon";

// import { Toast } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import GlobalStyles from "../../_global.scss";
import { IMAGES } from "../../Constant/ImageConstant";
import Toast, { success } from "../../Hooks/useToast";
import ChargingControlSvgIcon from "./SVG_Icons/ChargingControlSvgIcon";
import EnergyRatesSvgIcon from "./SVG_Icons/EnergyRatesSvgIcon";
import SupportSvgIcon from "./SVG_Icons/SupportSvgIcon";
import TransactionSvgIcon from "./SVG_Icons/TransactionSvgIcon";
import UserListingSvgIcon from "./SVG_Icons/UserListingSvgIcon";
import ThemeModal from "../modals/ThemeModal";
import { EmptyLocalStorage } from "../../services/localStorage/localStorage";
import { RiFormula } from "react-icons/ri";

export default function ProtectedLayout({
  title,
  isNavBar = false,
  isSearchBar,
  isSettingButton,
  selectedRoute,
  children,
}) {
  const [collapsed, setCollapsed] = useState(window.innerWidth > 991);
  const [logoutModal, setLogoutModal] = useState(false);
  // const [openSubmenu, setOpenSubmenu] = useState(null);
  // const [notificationModal, setNotificationModal] = useState(false);
  // const [filterModal, setFilterModal] = useState(false);

  const { primaryColor } = GlobalStyles;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleResize = () => {
    if (window.innerWidth < 991) {
      handleMenuClose();
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // todo: menu
  const items = [
    {
      label: STRING.Dashboard,
      icon: <DashboardSvgIcon />,
    },
    {
      label: STRING.UserListing,
      icon: <UserListingSvgIcon />,
    },
    {
      label: STRING.Transactions,
      icon: <TransactionSvgIcon />,
    },
    {
      label: STRING.ChargingControl,
      icon: <ChargingControlSvgIcon />,
    },

    {
      label: STRING.EnergyRates,
      icon: <EnergyRatesSvgIcon />,
    },
    {
      label: STRING.RateCalculation,
      icon: <RiFormula />,
    },

    // {
    //   label: STRING.Settings,
    //   icon: <SettingSvgIcon />,
    // },
    {
      label: STRING.Support,
      icon: <SupportSvgIcon />,
    },
  ];

  const onSubmitModal = () => {
    setLogoutModal(false);
    EmptyLocalStorage();
    success("logged out successfully");
    navigate("/account");
  };
  // const handleSubmenuToggle = (index) => {
  //   setOpenSubmenu(openSubmenu === index ? null : index);
  // };

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClose = () => {
    setCollapsed(false);
  };

  // const handleViewProfile = () => {
  //     navigate(baseRoute(WEB_ROUTES.PROFILE))
  //     if (collapsed && window.innerWidth < 991) {
  //         handleMenuClose();
  //     }
  // }

  const handleLogout = () => {
    // dispatch(updateLogin(false));

    try {
      // removeToken();

      // dispatch(
      //   unsetUserInfo({
      //     token: "",
      //     email: "",
      //     name: "",
      //     isLoggedIn: false,
      //     keepLoggedIn: "",
      //   })
      // );
      window.location.reload();
      Toast("Logged Out Successfully", "success");
    } catch {
      Toast("An error occurred during log out", "error");
    }
  };
  // const handleNotificationModalClose = () => {
  //   setNotificationModal(false);
  // };

  // const handleNotificationModalOpen = () => {
  //   // setNotificationModal(true)
  // };

  // const handleFilterModalClose = () => {
  //   setFilterModal(false);
  // };

  // const handleFilterModalOpen = () => {
  //   setFilterModal(true);
  // };
  // console.log(selectedRoute);
  return (
    <div className={`${styles.ProtectedLayout} `}>
      <div
        className={collapsed ? styles.background : ""}
        onClick={handleMenuClose}
      ></div>
      <div
        className={`${styles.menu} ${collapsed ? `${styles.collapsed} visible` : styles.notCollapsed
          }`}
        id="sider"
      >
        <div className={`${styles.menuOuter}`}>
          <div
            className={`p-3 ${styles.logo} ${!collapsed ? styles.notCollapsed : ""
              }`}
            to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}
          >
            {
              <img
                alt="Logo"
                src={collapsed ? IMAGES.LOGO : IMAGES.FAVICON}
                className={
                  collapsed ? styles.imageCollapsed : styles.imageNotCollapsed
                }
              />
            }
          </div>
          {items.map(({ icon, label }, index) => (
            <div key={index}>
              <Link
                key={index}
                to={`${process.env.REACT_APP_DOMAIN_DIR}/${label
                  .toLowerCase()
                  .replace(/[^A-Z0-9]/gi, "-")}`}
                onClick={
                  collapsed && window.innerWidth < 991
                    ? handleMenuClose
                    : () => { }
                }
                className={`${styles.item} ${index === selectedRoute ? styles.active : ""
                  } slide-in-left-fast`}
                style={{ animationDelay: `${index * 0.15}s` }}
              >
                {React.cloneElement(icon, {
                  color: index === selectedRoute ? primaryColor : "white",
                })}
                {collapsed ? (
                  <p
                    style={{
                      color: index === selectedRoute ? primaryColor : "white",
                      transition: "opacity 5s ease",
                    }}
                  >
                    {label}
                  </p>
                ) : (
                  ""
                )}
              </Link>
            </div>
          ))}
          <div
            className="d-flex justify-content-around align-items-center rounded-3 p-2 bg-white text-danger mx-auto fw-bold w-50 my-4"
            onClick={() => setLogoutModal(true)}
            style={{ cursor: "pointer" }}
          >
            {collapsed ? <span>Logout</span> : ""}
            <span style={{ fontSize: "16px", display: "flex" }}>
              <FaArrowRight />
            </span>
          </div>
        </div>
      </div>
      <div className={styles.routeContent}>
        {isNavBar ? (
          <Navbar
            title={title}
            isSearchBar={isSearchBar}
            isSettingButton={isSettingButton}
            handleToggle={handleToggle}
          // handleNotificationModalOpen={handleNotificationModalOpen}
          // handleFilterModalOpen={handleFilterModalOpen}
          />
        ) : (
          ""
        )}
        <div className={styles.content}>
          {
            // notificationModal ?
            //     <NotificationModal handleNotificationModalClose={handleNotificationModalClose} />
            //     :
            //     ''
          }

          {
            // filterModal ?
            //     <FilterModal handleFilterModalClose={handleFilterModalClose} />
            //     :
            //     ''
          }

          {children}
        </div>
      </div>
      <ThemeModal
        setState={setLogoutModal}
        initialState={logoutModal}
        onSubmitModal={onSubmitModal}
        cancelText={"Cancel"}
        deletetext={"Confirm"}
        heading={"Are you sure you want to Log Out?"}
        Text={"Click Confirm to log out"}
      />
    </div>
  );
}
