import api from ".";
import { apiUrl, constant } from "../Constant/constants";

export const getUserService = (params) =>
  api.get(`${constant.VER}${constant.admin}${apiUrl.user}?user_type=user`, {
    params,
  });

export const blockUnblockUser = (slug, data) => {
  const url = `${constant.VER}${constant.admin}${apiUrl.user}/${slug}`;
  return api.patch(url, data);
};

export const deleteUser = (slug) => {
  const url = `${constant.VER}${constant.admin}${apiUrl.user}/${slug}`;
  return api.delete(url);
};
