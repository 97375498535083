import api from ".";
import { apiUrl, constant } from "../Constant/constants";

export const getEnergyService = (params) =>
  api.get(`${constant.VER}${constant.admin}${apiUrl.company}`, {
    params,
  });

export const deleteUser = (slug) => {
  const url = `${constant.VER}${constant.admin}${apiUrl.company}/${slug}`;
  return api.delete(url);
};

export const addEnergy = (data) =>
  api.post(`${constant.VER}${constant.admin}${apiUrl.company}`, data);

// export const updateEnergy = (slug, data) =>
//   api.patch(`${constant.VER}${constant.admin}${apiUrl.company}${slug}`, data);

export const updateEnergy = (slug, data) => {
  const url = `${constant.VER}${constant.admin}${apiUrl.company}/${slug}`;
  console.log(url, "pppp");

  return api.patch(url, data);
};
